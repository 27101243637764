// Override Bulma variables for E.S.R. Thêta specific colors
$body-background-color: rgba(var(--body-background-color), 1);
$body-size: 14px;
$card-footer-background-color: #f1f1f1;
$footer-background-color: #f1f1f1;
$primary: #ffdd00;
$light-primary: #fdee95;
$lustrum: #9b1b1f;
$dark-theme: rgb(37,37,37);

:root{
    --body-background-color: 241,241,241;
}





// Dark-mode added by Olivier
// Made with css instead of Bulma, because some Bulma variables where not all known
.dark-mode {
    --body-background-color: 0,0,0;
    nav {
        background-color: $dark-theme;
        .navbar-link{
            color: white;
            background-color: transparent !important;
        }
        .navbar-menu{
            .navbar-item {
                // background-color: $dark-theme !important;
                color: white !important;
                &:hover{
                    background-color: black !important;
                }
                &:focus {
                    background-color: black !important;
                }
            }
        }
        .navbar-dropdown
        {
            background-color: $dark-theme !important;
        }
        #mobile-main-pages{
            background-color: $dark-theme;
        }
    }

    .tabs {
        a.is-inactive {
            background-color: $dark-theme !important;
            border-color: black !important;
        }
        a.is-active {
            background-color: $primary !important;
            color: black !important;
            border-color: $primary !important;
        }
    }


    textarea,
    input{
        background-color: $dark-theme !important;
        color: white !important;
        &::placeholder{
            color: grey !important;
        }
    }
    esr-hero-image-banner {
        // add filter to darken the image
        filter: brightness(0.5);
    }
    .card {
        background-color: $dark-theme;
        color: white;
        header {
            background-color: $primary;
        }
    }
    .card-content, .content{
        color:white !important;
        .title {
            color: white;
        }
        .subtitle {
            color: white;
        }
        strong {
            color: $primary !important;
        }
        label{
            color: white;
        }
        h1{
            color: white !important;
        }
        h2{
            color: white !important;
        }
        h3{
            color: white !important;
        }
        h4{
            color: white !important;
        }
        h5{
            color: white !important;
        }
        h6{
            color: white !important;
        }
        blockquote{
            background-color: black !important;
        }
    }
    
    table{
        background-color: $dark-theme !important;
        color: white !important;
        th{
            color: white !important;
        }
        // change color when hovering over table row. Not in thead
        tbody tr:hover{
            background-color: black !important;
            color: white !important;
        }

    }
    .box{
        background-color: $dark-theme !important;
        color: white !important;
        .title {
            color: white;
        }
        .subtitle {
            color: white;
        }
    }
    .card-content .box{
        background-color: rgb(48, 48, 48) !important;
    }
    .link-item {
        color: white;
    }
    .column {
        color: white !important;
        a:not(.notification *):not(.button){
            color: $primary !important;
        }
    }
    .tabs.is-boxed{
        a:hover{
            background-color: black !important;
        }
        li.is-active a{
            background-color: black !important;
        }
    }
    #column-left {
        background-color: $dark-theme !important;
        &:hover {
              background-color: black !important;
            }
      }
    #column-right {
    background-color: $dark-theme !important;
    &:hover {
            background-color: black !important;
        }
      }
    footer{
        background-color: $dark-theme !important;
        color: white;
        strong{
            color: white;
        }
    }

    body{
        background: black !important;
    }
    .button{
        color: black;
    }
    button:not(.is-primary, .is-link, .is-info, .is-success, .is-warning, .is-danger),
    a.button:not(.is-primary, .is-link, .is-info, .is-success, .is-warning, .is-danger),
    .file-cta,
    select{
        background-color: $dark-theme !important;
        color: white !important;
    }
    .menu-list a.is-active{
        color: black !important;
    }
    .menu-list li>a:hover{
        background-color: black !important;
        color: $primary !important;
    }
    .navbar-burger{
        color: white;
    }
    .modal-card-body{
        background-color: $dark-theme;
    }
    // lustrum
    .card:has(+ h2) {
        background-color: $dark-theme;
        color: #fff4e2;
        header {
            background-color: $lustrum;
        }
        .card-header-title {
            color: #fff4e2;
        }
        
    }
    .card-content:has(+ h2), .content{
        color:#fff4e2 !important;
        .title {
            color: #fff4e2;
        }
        .subtitle {
            color: #fff4e2;
        }
        strong {
            color: #fff4e2 !important;
        }
        
        label{
            color: #fff4e2;
        }
        h1{
            color: #fff4e2 !important;
        }
        h2{
            color: #fff4e2 !important;
        }
        h3{
            color: #fff4e2 !important;
        }
        h4{
            color: #fff4e2 !important;
        }
        h5{
            color: #fff4e2 !important;
        }
        h6{
            color: #fff4e2 !important;
        }
        blockquote{
            background-color: #fff4e2 !important;
        }
    }
    footer:has(+ h2) {
        background-color: $dark-theme !important;
        color: #fff4e2;
        strong{
            color: #fff4e2;
        }
    }
    .column:has(+ h2) {
        color: #fff4e2 !important;
        a:not(.notification *):not(.button){
            color: #fff4e2 !important;
        }
    }
    .notification:has(+ h2) {
        background-color: $dark-theme !important;
        color: #9b1b1f !important;
    }
    #column-left:has(+ h2) {
        background-color: $dark-theme !important;
        &:hover {
              background-color: #c37679 !important;
              .strong {
                color: #fff4e2 !important;
            }
        }
      }
    #column-right:has(+ h2) {
    background-color: $dark-theme !important;
    &:hover {
            background-color: #c37679 !important;
            .strong {
                color: #fff4e2 !important;
            }
        }
      }
}

:root {
    color-scheme: light dark;
}

// Ensure Bulma's functions, mixins, variables, etc. are available whenever
// the theme is loaded (e.g. the `mobile` mixin). As these do not add any
// bloat to the final compiled CSS it's fine to always include them (as
// opposed to including all of Bulma all the time)
@import "bulma/sass/utilities/_all";
